* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Jost', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.news-card {
  transition: all 1s ease; 
  cursor: pointer;
}
.news-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px 2px rgba(255, 193, 7, 1);  
}

.card-container {
  height: 550px;
}

.card-image {
  width: 100%;
  height: 300px;
  object-fit: contain;
}

.button-card a {
  text-decoration: none;
  color: #000;
}

.btn-outline-warning:hover {
  color: #fff !important;
}

button {
  cursor: pointer;
}

.post {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  margin: 20px auto;
  padding: 15px 10px;
  background: #fff;
}

small {
  border: 2px solid #777;
  border-radius: 7px;
  padding: 5px 12px;
  color: #777;
}

.navbar-brand {
  cursor: pointer;
}

.NoPageFound-image {
  width: 100%;
  height: 500px;
  object-fit: contain;
}

.page-link:hover {
  background-color: #ffc107;
  color: #fff !important;
}

.page-item.active .page-link {
  background-color: #ffc107 !important;
  color: #fff !important;
}

.serach-svg {
  right: 25px;
  top: 20px;
}

.form-control::-webkit-search-cancel-button {
  position: relative;
  right: 20px;
}

.form-control::-ms-clear {
  margin-right: 20px
}

.logo-image {
  width: 100px;
  height: 60px;
  object-fit: cover;
}

.logoFooter-image {
  width: 100px;
  height: 60px;
  object-fit: cover;
  position: relative;
  left: 140px;
}

.footer-conatiner {
  cursor: pointer;
}

.footer-content p:hover {
  color: #ffc107 !important;
}

.footer-icons li a:hover {
  color: #ffc107 !important;
}

.input-control {
  width: 300px;
}

/* Media Query */

@media (max-width: 1200px) and (min-width: 780px) {
  .logoFooter-image {
    left: 55px;
  }
}

@media(min-width: 630px) and (max-width: 779px) {
  .logoFooter-image {
    left: 10px;
  }
}

@media(min-width: 450px) and (max-width: 629px) {
  .input-control {
    width: 200px;
  }

  .logoFooter-image {
    left: 5px;
    width: 70px;
    height: 50px;
  }
}

@media(min-width: 270px) and (max-width: 449px) {
  .logo-image {
    position: relative;
    left: 170px;
    width: 70px;
    height: 60px;
  }

  .input-control {
    width: 390px;
  }

  .serach-svg {
    top: 75px;
  }

  .footer-content {
    flex-direction: column;
  }

  .logoFooter-image {
    left: 0;
    width: 70px;
    height: 60px;
  }
  .news-heading {
    position: relative;
    top: 50px;
    font-size: 35px;
  }
}